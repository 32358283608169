import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import "./styles/globals.scss";
import PageNotFound from "./pages/404";
import { useEffect, useState } from "react";
import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import Loader from "./components/Loader";
import { PageLayout } from "./components/Layout";
import Login from "./pages/Login";
import SessionService from "./services/SessionService";
import AADLoginResponse from "./pages/AADLoginResponse";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "./services/ApiService";
import RolesList from "./pages/Roles/index";
import ProductList from "./pages/Products/ProductList";
import UserList from "./pages/Users";
import ProjectStageSetting from "./pages/ApplicationSetting/Project-Stage-Setting";
import ProjectCoordinatorSetting from "./pages/ApplicationSetting/CoordinatorSetting";
import AttachmentTypeSetting from "./pages/ApplicationSetting/AttachmentTypeSetting";
import MarketingDesignerSetting from "./pages/ApplicationSetting/MarketingDesignerSetting";
import RoyaltyDesignerSetting from "./pages/ApplicationSetting/RoyaltyDesignerSetting";
import SupplierSetting from "./pages/ApplicationSetting/SupplierSetting";
import ProductFinishSetting from "./pages/ApplicationSetting/ProductFinishSetting";
import PrimaryMaterialSetting from "./pages/ApplicationSetting/PrimaryMaterialSetting";
import HTSMaterialSetting from "./pages/ApplicationSetting/HTSMaterialSetting";
import HTSDetailSetting from "./pages/ApplicationSetting/HTSMaterialDetailSetting";
import StockCodeHierarchySetting from "./pages/ApplicationSetting/StockCodeHierarchySetting";
import BaseCodeHierarchySetting from "./pages/ApplicationSetting/BasecodeHierarchySetting";
import CategorySetting from "./pages/ApplicationSetting/CategorySetting";
import DataFieldTypeSetting from "./pages/ApplicationSetting/DataFieldTypeSetting";
import CreateProduct from "./pages/Products/CreateProduct";
import ManufactoryMethodSetting from "./pages/ApplicationSetting/ManufacturingMethodSetting";
import BulkUpload from "./pages/Products/BulkUpload";
import BulkUpdate from "./pages/Products/BulkUpdate";
import SecondaryMaterialSetting from "./pages/ApplicationSetting/SecondaryMaterialSetting";
import FactoryStatusSetting from "./pages/ApplicationSetting/FactoryStatusSetting";
import ProjectStatusSetting from "./pages/ApplicationSetting/ProjectStatusSetting";
import ProtectedRoutes from "./components/ProtectedRoutes";
import DataTypeSetting from "./pages/ApplicationSetting/DataTypeSetting";
import TagSetting from "./pages/ApplicationSetting/TagSetting";
import EditProduct from "./pages/Products/EditItem/EditProduct";
import { Permissions } from "./models/Enum";
import AttachmentList from "./pages/Report/ViewAttachment/AttachmentList";
import Dashboard from "./pages/Dashboard/Dashboard";
import ItemfunctionSetting from "./pages/ApplicationSetting/ItemFunctionSetting";
import Assignment from "./pages/ApplicationSetting/Assignment";
import SecurityGroup from "./pages/ApplicationSetting/SecurityGroup";
import SkuTypeSetting from "./pages/ApplicationSetting/SkuTypeSetting";
import StyleSetting from "./pages/ApplicationSetting/StyleSetting";
import CreateUpdateQuote from "./pages/Products/CreateUpdateQuote";
import QuoteList from "./pages/Products/QuoteList";

function App() {
  const sessionService = SessionService.getInstance();
  const [loader, setLoader] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(
    sessionService.isLoggedIn()
  );
  const navigate = useNavigate();
  const defaultMessage =
    "Error response received from server. Please try again after refreshing your page";

  useEffect(() => {
    setIsAuthenticated(sessionService.isLoggedIn());
  }, [sessionService]);

  useEffect(() => {
    api.axiosInstance.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        setLoader(true);
        if (
          config.url &&
          !config.url.includes(process.env.REACT_APP_API_URL as string)
        ) {
          config.url = process.env.REACT_APP_API_URL + config.url;
        }

        const sessionInfo: any = JSON.parse(
          window?.localStorage.getItem("session") || "{}"
        );
        const key = process.env.REACT_APP_API_KEY;
        if (key) {
          config.headers["x-api-key"] = key;
        }

        if (sessionInfo.token) {
          config.headers.Authorization = `Bearer ${sessionInfo.token}`;
        }

        return config;
      },
      (err: any) => {
        setLoader(false);
        return Promise.reject(err);
      }
    );

    api.axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        setLoader(false);
        return response;
      },
      (err: any) => {
        setLoader(false);

        if (err?.response?.status === 401) {
          sessionService.clearSession();
          sessionService.clearReduxState();
          navigate("/login");
        } else if (err?.response?.status === 404) {
          toast.error(err?.response?.data || err.message || "Could not find the requested resource");
        } else if(err?.response?.status === 400) {
          toast.error(err?.response?.data?.message || err?.response?.data || defaultMessage);
        } else {
          toast.error(() => (
            <span
              dangerouslySetInnerHTML={{ __html: getErrorDescription(err) }}
            />
          ));
        }

        return Promise.reject(err);
      }
    );
  }, [api.axiosInstance]);

  //Extracts error details from the API Response
  const getErrorDescription = (err: AxiosError): string => {
    if (err?.response?.data) {
      const errorData = err.response.data as any;
      if (typeof errorData === "object" && errorData.errors) {
        if (typeof errorData.errors === "object") {
          const errorsObjectKeys = Object.keys(errorData.errors);
          const errors = [];
          errorsObjectKeys.map((key) => {
            if (Array.isArray(errorData.errors[key])) {
              errors.push(...errorData.errors[key]);
            } else errors.push(errorData.errors[key]);
          });

          return (
            `<strong>${
              errorData.title ||
              "One or more errors occurred while processing the request"
            }</strong>` +
            " </br> - " +
            errors.join("</br> - ")
          );
        } else return errorData.errors as string;
      } 
      else if(Array.isArray(errorData)) {
        // this could be the validation errors
        const errors =  (errorData as any[])
          .filter(e => !!e.errorMessage)
          .map(e => e.errorMessage);
        
          if(errors?.length > 0) {
            return (
              `<strong>${
                "One or more errors occurred while processing the request"
              }</strong>` +
              " </br> - " +
              errors.join("</br> - ")
            );
          }
          else {
            return defaultMessage;
          }
      }
      else if(errorData.errors?.length) {
        return (
          `<strong>${
            err?.message || "One or more errors occurred while processing the request"
          }</strong>` +
          " </br> - " +
          errorData.errors.join("</br> - ")
        );
      }
      else if(typeof err.response.data === "string") {
        return (err.response.data as string);
      }
      else return err?.message || defaultMessage;
    } 
    else return err?.message || defaultMessage;
  };

  return (
    <>
      <ToastContainer style={{ maxWidth: "50%" }} />
      {isAuthenticated && (
        <PageLayout>
          <ProtectedPages />
        </PageLayout>
      )}
      {!isAuthenticated && <UnAuthenticationPages />}
      {loader && <Loader isLoading={loader} />}
    </>
  );
}

function ProtectedPages() {
  const sessionService = SessionService.getInstance();
  return (
    <Routes>
      <Route element={<ProtectedRoutes />}>
        <Route path="/" element={<Navigate to={sessionService.hasPermission(Permissions.ExecutiveDashboard) ? "/dashboard" : "/products"} />} />
        {sessionService.hasPermission(Permissions.ExecutiveDashboard) &&
          <Route path="/dashboard" element={<Dashboard />} />}
        {(sessionService.hasPermission(Permissions.ManageUserRoles) || sessionService.hasPermission(Permissions.ViewUsers)) &&
        <Route path="/roles" element={<RolesList />} />
        }
        {(sessionService.hasPermission(Permissions.CreateUser) || sessionService.hasPermission(Permissions.UpdateUser) || 
        sessionService.hasPermission(Permissions.ViewUsers)) &&
          <Route path="/users" element={<UserList />} />
        }        
        {(sessionService.hasPermission(Permissions.ViewProducts) || sessionService.hasPermission(Permissions.UpdateProduct) 
        || sessionService.hasPermission(Permissions.CreateProduct)) &&
          (<>
            <Route path="/products" element={<ProductList />} />         
            <Route path="/edit-product/:editItemId" element={<EditProduct />} />
          </>)
        }

        {(sessionService.hasPermission(Permissions.ViewQuotes) || sessionService.hasPermission(Permissions.ManageQuote)
          || sessionService.hasPermission(Permissions.ApproveQuoteCost) || sessionService.hasPermission(Permissions.ApproveDNAndUMAP)
          || sessionService.hasPermission(Permissions.ViewQuoteHistory)) &&
          (<>
            <Route path="/quotes" element={<QuoteList />} />
            <Route path="/edit-quote/:id" element={<CreateUpdateQuote />} />
          </>)
        }

        {sessionService.hasPermission(Permissions.ManageQuote) &&
          <>
            <Route path="/create-quote" element={<CreateUpdateQuote />} />
          </>
        }

        {sessionService.hasPermission(Permissions.CreateProduct) &&
          <>
            <Route path="/create-product" element={<CreateProduct />} />
          </>
        }
        {sessionService.hasPermission(Permissions.BulkUpdateProducts) &&        
          <Route path="/bulk-update" element={<BulkUpdate />} />
        }
        {sessionService.hasPermission(Permissions.BulkCreateProducts) &&
          <Route path="/bulk-create" element={<BulkUpload />} />
        }
        {(sessionService.hasPermission(Permissions.ManageAppSettings) || sessionService.hasPermission(Permissions.ViewAppSetting)) &&
          (<>
            <Route path="/tag" element={<TagSetting/>} /> 
            <Route path="/project-stage" element={<ProjectStageSetting />} /> 
            <Route path="/project-status" element={<ProjectStatusSetting />} /> 
            <Route path="/factory-status" element={<FactoryStatusSetting />} /> 
            {/* <Route path="/project-coordinator" element={<ProjectCoordinatorSetting />} />  */}
            <Route path="/attachment-type" element={<AttachmentTypeSetting />} /> 
            <Route path="/royalty-designer" element={<RoyaltyDesignerSetting />} /> 
            <Route path="/marketing-designer" element={<MarketingDesignerSetting />} /> 
            <Route path="/suppliers" element={<SupplierSetting />} /> 
            <Route path="/finishes" element={<ProductFinishSetting />} /> 
            <Route path="/primary-material" element={<PrimaryMaterialSetting />} /> 
            <Route path="/secondary-material" element={<SecondaryMaterialSetting />} /> 
            <Route path="/hts-material" element={<HTSMaterialSetting />} /> 
            <Route path="/hts-material-detail" element={<HTSDetailSetting />} /> 
            <Route path="/stockcode-image-hierarchy" element={<StockCodeHierarchySetting />} /> 
            <Route path="/basecode-image-hierarchy" element={<BaseCodeHierarchySetting />} /> 
            <Route path="/category-(and-sort-order)" element={<CategorySetting />} /> 
            <Route path="/data-type" element={<DataTypeSetting />} />
            <Route path="/data-field-type" element={<DataFieldTypeSetting />} /> 
            <Route path="/manufacturing-method" element={<ManufactoryMethodSetting />} />
            <Route path="/function" element={<ItemfunctionSetting/> }/>
            <Route path="/sku-type" element={<SkuTypeSetting/> }/>
            <Route path="/style" element={<StyleSetting/>}/>
          </>)
        }
        <Route path="/assignment" element={<Assignment />} />
        <Route path="/security-group" element={<SecurityGroup/> }/>
        <Route path="/view-attachment" element={<AttachmentList />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
}

function UnAuthenticationPages() {
  return (
    <Routes>
      <Route path="/aad-login-response" element={<AADLoginResponse />} />
      <Route path="*" element={<Login />} />
    </Routes>
  );
}

export default App;
