import { useState, useEffect } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridRowParams,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import api from "../../services/ApiService";
import { ApiResponse } from "../../models/ApiResponse";
import {
  SelectedFilters,
} from "../../models/Items/ItemListRequest";
import { Permissions, PermissionsFriendlyNames, SortDirection } from "../../models/Enum";
import Filters from "./Filters";
import {
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@mui/material";
import Loader from "../../components/Loader";
import EditIcon from "@mui/icons-material/Edit";
import { GRID_ACTION_DELETE_ICON, NO_LOCAL_SEARCH_RESULTS_GRID_MESSAGE, NO_ROWS_GRID_MESSAGE } from "../../components/GridUtilityComponents";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store";
import SessionService from "../../services/SessionService";
import DeleteValidationModal from "../../components/ManageSettingComponents/DeleteValidationModal";
import { updateQuoteListRequest } from "../../reducers/quotesReducer";
import { QuoteBasicDetail } from "../../models/Quotes/QuoteBasicDetail";
import moment from "moment";
import { DATE_FORMAT } from "../../utils/constants";
import ExportQuoteListing from "../../components/ExportQuoteListing";

export interface QuoteListResponse {
  productQuotes?: QuoteBasicDetail[];
  totalCount?: number;
}

export default function QuoteList() {
  const [isLoading, setLoading] = useState(false);
  const [quotes, setQuotes] = useState<QuoteBasicDetail[]>([]);

  const [allRecordsSelected, setAllRecordsSelected] = useState(false);

  const [quotesTotalCount, setQuotesTotalCount] = useState(0);
  const [filtersExpanded, setFiltersExpanded] = useState<boolean>(true);

  const [deleteModalOpen, setDeleteOpenModal] = useState(false);
  const [quoteId, setQuoteId] = useState(0);

  let navigate = useNavigate();

  const dispatch = useAppDispatch();

  const quoteListRequestState = useAppSelector((state) => state.quotes.quoteListRequest);

  const sessionService = SessionService.getInstance();

  const initialQuoteListRequest = {
    pageIndex: 1,
    pageSize: 10,
    sortBy: "id",
    sortDirection: SortDirection.DESC,
  };

  const actionColumn = {
    field: "actions",
    headerName: "Action",
    sortable: false,
    width: 100,
    renderCell: (params: GridCellParams) => (
      <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
        <GridActionsCellItem
          icon={<EditIcon />}
          onClick={() =>
            params?.row?.quoteId !== 0
              ? navigate(`/edit-quote/${params?.row?.quoteId}`, { state: { productId: params?.row?.productId } })
              : sessionService.hasPermission(Permissions.ManageQuote) && navigate(`/create-quote`, { state: { productId: params?.row?.productId } })
          }
          label="Edit"
          disabled={params?.row?.quoteId === 0 && !sessionService.hasPermission(Permissions.ManageQuote)}
        />
        {sessionService.hasPermission(Permissions.ManageQuote) && params?.row?.quoteId !== 0 && GRID_ACTION_DELETE_ICON(params, handleDeleteModalOpen)}
      </div>
    ),
  };

  const QuoteListGridColumns: GridColDef[] = [
    { field: "productId", headerName: "Product Id", width: 120, disableColumnMenu: true },
    {
      field: "stockCode",
      headerName: "Stock Code",
      disableColumnMenu: true,
      width: 125,
    },
    {
      field: "pdStockcode",
      headerName: "PD StockCode",
      disableColumnMenu: true,
      width: 130,
    },
    {
      field: "baseCode",
      headerName: "Base Code",
      disableColumnMenu: true,
      width: 130,
    },
    {
      field: "pdCode",
      headerName: "PD BaseCode",
      disableColumnMenu: true,
      width: 130,
    },
    {
      field: "brandDisplayText",
      headerName: "Brand Collection",
      disableColumnMenu: true,
      width: 130
    },
    {
      field: "shortDesc",
      headerName: "Short Description",
      disableColumnMenu: true,
      width: 180
    },
    {
      field: "fobCost",
      headerName: "FOB Cost",
      sortable: false,
      disableColumnMenu: true,
      width: 100
    },
    {
      field: "ipCube",
      headerName: "IP Cube",
      sortable: false,
      disableColumnMenu: true,
      width: 100
    },
    {
      field: "dnPrice",
      headerName: "DN",
      sortable: false,
      disableColumnMenu: true,
      width: 100
    },
    {
      field: "umapPrice",
      headerName: "UMAP",
      sortable: false,
      disableColumnMenu: true,
      width: 100
    },
    {
      field: "grossGMPercent",
      headerName: "Gross GM Percent",
      sortable: false,
      disableColumnMenu: true,
      width: 140
    },
    {
      field: "vendorDisplayText",
      headerName: "Vendor",
      sortable: false,
      disableColumnMenu: true,
      width: 120
    },
    {
      field: "quoteDate",
      headerName: "Quote Date",
      sortable: false,
      disableColumnMenu: true,
      width: 120,
      valueGetter: (params) => params?.value ? moment(params?.value).format(DATE_FORMAT) : ''
    },
    {
      field: "quoteApprovalType",
      headerName: "Quote Approval Type",
      sortable: false,
      disableColumnMenu: true,
      width: 160
    },
    {
      field: "height",
      headerName: "Height",
      sortable: false,
      disableColumnMenu: true,
      width: 120,
    },
    {
      field: "width",
      headerName: "Width",
      sortable: false,
      disableColumnMenu: true,
      width: 120,
    },
    {
      field: "stageDisplayText",
      headerName: "Stage",
      sortable: false,
      disableColumnMenu: true,
      width: 120,
    },
    {
      field: "statusDisplayText",
      headerName: "Status",
      sortable: false,
      disableColumnMenu: true,
      width: 155,
    },
    {
      field: "pdFamilyName",
      headerName: "PD Family",
      sortable: false,
      disableColumnMenu: true,
      width: 130,
      align: 'center',
    },
    {
      field: "seriesName",
      headerName: "Series Name",
      sortable: false,
      disableColumnMenu: true,
      width: 110,
    },
    {
      field: "marketingDesignerDisplayText",
      headerName: "Designer",
      sortable: false,
      disableColumnMenu: true,
      width: 155,
    },
    {
      field: "categoryDisplayText",
      headerName: "Category",
      sortable: false,
      disableColumnMenu: true,
      width: 80,
    },
    {
      field: "functionDisplayText",
      headerName: "Function",
      sortable: false,
      disableColumnMenu: true,
      width: 90,
    },
    {
      field: "primaryMaterialDisplayText",
      headerName: "Primary Material",
      sortable: false,
      disableColumnMenu: true,
      width: 130,
      align: 'center',
    },
    {
      field: "secondaryMaterialDisplayText",
      headerName: "Secondary Material",
      sortable: false,
      disableColumnMenu: true,
      width: 150,
      align: 'center',
    },
  ];

  // mapping used to find original column name that should be used e.g. for sortBy parameter
  const QuoteListReferenceColumnsMapping = {
    brandDisplayText: "brand",
    stageDisplayText: "stage",
    statusDisplayText: "status",
    marketingDesignerDisplayText: "marketingDesigner",
    categoryDisplayText: "category",
    functionDisplayText: "function",
    productId: "id"
  };

  const handleClose = () => {
    setDeleteOpenModal(false);
  }

  const handleDeleteModalOpen = (params) => {
    setDeleteOpenModal(true);
    setQuoteId(params.row.quoteId);
  };

  const deleteSubmit = async () => {
    try {
      api
        .delete<ApiResponse<boolean>>(`/quote/${quoteId}`)
        .then(async (response: any) => {
          if (response.isSuccess) {
            toast.success(response.message);
            setDeleteOpenModal(false);
            await getQuotes();
          }
        })
        .catch((error) => {
          setDeleteOpenModal(false);
          toast.error(error?.message);
        });
    } catch (error: any) {
      setDeleteOpenModal(false);
      toast.error(error.message);
    }
  };

  const gridToolbar = () => (
    <GridToolbarContainer>
      <div style={{ marginLeft: "12px" }}>
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
        <ExportQuoteListing
          // selectedRows={selectedRows}
          quotesTotalCount={quotesTotalCount}
          // selectAll={allRecordsSelected}
          setLoading={setLoading}
        />
      </div>
      <div style={{ flexGrow: 1, padding: 5 }}>
        <GridToolbarQuickFilter sx={{ margin: "5px 30px 5px 5px", width: "30%" }} />
      </div>
    </GridToolbarContainer>
  );

  useEffect(() => {
    getQuotes();
  }, [quoteListRequestState]);

  const getQuotes = async () => {
    try {
      setLoading(true);
      api
        .post<ApiResponse<QuoteListResponse>>("/Quote/list", quoteListRequestState)
        .then((response) => {
          setLoading(false);
          if (response.isSuccess) {
            setQuotesTotalCount(response?.data?.totalCount);
            const productQuotes: QuoteBasicDetail[] = (response?.data?.productQuotes || []);
            setQuotes(productQuotes);
          } else {
            throw new Error(response.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("Exception from product list", error);
        });
    } catch (error: any) {
      setLoading(false);
      console.error("Exception from product list", error);
    }
  };

  // if sorted by a field which is not direct field of product table then find the correct column from mapping
  const getSortFieldName = (gridFieldName: string): string => {
    return QuoteListReferenceColumnsMapping[gridFieldName] || gridFieldName;
  };

  // convert state sortBy value to column name that is bound in grid
  const getReverseSortFieldName = (fieldName: string): string => {
    const gridFieldName = Object.keys(QuoteListReferenceColumnsMapping).find(
      (prmKey) => QuoteListReferenceColumnsMapping[prmKey] === fieldName
    );
    return gridFieldName || fieldName;
  };

  const onSortChange = (sorting: GridSortModel) => {
    if (sorting.length === 0 || !sorting[0]?.field) return;

    dispatch(updateQuoteListRequest({
      ...quoteListRequestState,
      sortBy: getSortFieldName(sorting[0].field),
      sortDirection:
        sorting[0].sort === "asc" ? SortDirection.ASC : SortDirection.DESC,
    }));
  };

  const onPaginationChange = (pagination: GridPaginationModel) => {
    dispatch(updateQuoteListRequest({
      ...quoteListRequestState,
      pageIndex: pagination.page + 1,
      pageSize: pagination.pageSize,
    }));
  };

  const handleApplyFilterClick = (filterValues: SelectedFilters) => {
    if (filterValues.projectCode) {
      dispatch(updateQuoteListRequest({
        ...quoteListRequestState,
        projectCode: filterValues.projectCode,
        pageIndex: 1,
      }));
    } else if (Object.values(filterValues)?.length) {
      dispatch(updateQuoteListRequest({
        ...quoteListRequestState,
        ...filterValues,
        pageIndex: 1,
      }));
    } else {
      //reset the filters
      dispatch(updateQuoteListRequest(initialQuoteListRequest));
      setAllRecordsSelected(false);
    }
  };

  const handleExpandClick = () => {
    setFiltersExpanded(!filtersExpanded);
  };

  return (
    <>
      {deleteModalOpen &&
        <DeleteValidationModal
          addModalOpen={deleteModalOpen}
          handleCloseModal={handleClose}
          deleteSubmit={deleteSubmit}
          fieldIdentifier={`quote #${quoteId}`}
        />}
      <Filters
        onApplyFilter={handleApplyFilterClick}
        filtersExpanded={filtersExpanded}
        handleExpandClick={handleExpandClick}
        viewMode={PermissionsFriendlyNames[Permissions.ViewQuotes]}
      />
      <Loader isLoading={isLoading} />
      <Box
        sx={{
          height: "70%",
          width: "100%",
          display: "block",
        }}
      >
        <DataGrid
          rows={quotes.map((quote) => ({ ...quote, id: quote.productId, quoteId: quote.id }))}
          columns={[actionColumn, ...QuoteListGridColumns]}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10
              },
            },
          }}
          rowCount={quotesTotalCount}
          pageSizeOptions={[10, 25, 50, 100]}
          disableRowSelectionOnClick={true}
          disableColumnFilter={true}
          sortingMode="server"
          paginationMode="server"
          sortModel={[
            {
              field: getReverseSortFieldName(quoteListRequestState.sortBy),
              sort:
                quoteListRequestState.sortDirection == SortDirection.ASC
                  ? "asc"
                  : "desc",
            },
          ]}
          onSortModelChange={onSortChange}
          onPaginationModelChange={onPaginationChange}
          paginationModel={{
            page: quoteListRequestState.pageIndex - 1,
            pageSize: quoteListRequestState.pageSize,
          }}
          slots={{
            toolbar: gridToolbar,
            noRowsOverlay: () => NO_ROWS_GRID_MESSAGE,
            noResultsOverlay: () => NO_LOCAL_SEARCH_RESULTS_GRID_MESSAGE,
          }}
          hideFooterSelectedRowCount={allRecordsSelected}
          getRowHeight={() => 'auto'}
          columnVisibilityModel={{
            actions: (
              sessionService.hasPermission(Permissions.ManageQuote) ||
              sessionService.hasPermission(Permissions.ApproveQuoteCost) ||
              sessionService.hasPermission(Permissions.ApproveDNAndUMAP) ||
              sessionService.hasPermission(Permissions.ViewQuotes) ||
              sessionService.hasPermission(Permissions.ViewQuoteHistory)
            )
          }}
        />
      </Box>
    </>
  );
}