import React, { useEffect, useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

interface ThreeStateToggleProps {
  value?: 'yes' | 'none' | 'no'; // Initial value to determine the toggle state
  onChange?: (value: 'yes' | 'none' | 'no') => void; // Callback for when the toggle state changes
  fieldName: string;
  readOnlyField?: boolean;
}

const ThreeStateToggle: React.FC<ThreeStateToggleProps> = ({
  value = 'none', // Default to 'none' if no value is provided
  onChange,
  fieldName,
  readOnlyField
}) => {
  const [alignment, setAlignment] = useState<'yes' | 'none' | 'no'>(value);

  // Sync with the external `value` prop when it changes
  useEffect(() => {
    setAlignment(value);
  }, [value]);

  const handleAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: 'yes' | 'none' | 'no') => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      onChange && onChange(newAlignment); // Trigger the onChange callback if provided
    }
  };

  return (
    <ToggleButtonGroup
      value={alignment}
      exclusive
      onChange={handleAlignment}
      aria-label={fieldName}
      disabled={readOnlyField}
    >
      <ToggleButton value="yes" aria-label="yes" name={fieldName}>
        Yes
      </ToggleButton>
      <ToggleButton value="none" aria-label="none" name={fieldName}>
        None
      </ToggleButton>
      <ToggleButton value="no" aria-label="no" name={fieldName}>
        No
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ThreeStateToggle;
