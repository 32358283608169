import { useState, useEffect } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridPaginationModel,
  GridRowId,
  GridRowParams,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import {
  ProductListGridColumns,
  ProductListReferenceColumnsMapping,
} from "./ProductContstants";
import api from "../../services/ApiService";
import { ApiResponse } from "../../models/ApiResponse";
import { ItemBasicDetail } from "../../models/Items/ItemBasicDetail";
import {
    SelectedFilters,
} from "../../models/Items/ItemListRequest";
import { Permissions, PermissionsFriendlyNames, SortDirection } from "../../models/Enum";
import Filters from "./Filters";
import {
    Button,
    Box,
    FormControlLabel,
    Checkbox,
    Tooltip,
} from "@mui/material";
import Loader from "../../components/Loader";
import EditIcon from "@mui/icons-material/Edit";
import { GRID_ACTION_DELETE_ICON, NO_LOCAL_SEARCH_RESULTS_GRID_MESSAGE, NO_ROWS_GRID_MESSAGE } from "../../components/GridUtilityComponents";
import moment from "moment";
import { toast } from "react-toastify";
import ExtractAttachmentsModal from "./ExtractAttachmentModal";
import { FileDownloadRounded, Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store";
import { updateItemListRequest } from "../../reducers/itemsReducer";
import SessionService from "../../services/SessionService";
import ExportProductListing from "../../components/ExportProductListing";
import DeleteValidationModal from "../../components/ManageSettingComponents/DeleteValidationModal";
import { PRODUCT_TYPE_DROPDOWN_OPTIONS } from "./EditItem/Constants";
import SyncIcon from '@mui/icons-material/Sync';

export interface ProductListResponse {
  items?: ItemBasicDetail[]
  totalCount?: number
}

export default function ProductList() {  
  const [isLoading, setLoading] = useState(false);
  const [items, setItems] = useState<ItemBasicDetail[]>([]);

  const [openExtractAttachment, setOpenExtractAttachment] = useState(false);
  const [disableExtractAttachmentSubmit, setDisableExtractAttachmentSubmit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [allRecordsSelected, setAllRecordsSelected] = useState(false);

  const [productsTotalCount, setProductsTotalCount] = useState(0);
  const [filtersExpanded, setFiltersExpanded] = useState<boolean>(true);

  const [deleteModalOpen, setDeleteOpenModal] = useState(false);
  const [projectId , setProjectId] = useState(0);

  let navigate = useNavigate();

  const dispatch = useAppDispatch();

  const itemListRequestState = useAppSelector((state) => state.items.itemListRequest);

  const sessionService = SessionService.getInstance();

  const initialItemListRequest = {
    pageIndex: 1,
    pageSize: 10,
    sortBy: "id",
    sortDirection: SortDirection.DESC,
    developmentType: PRODUCT_TYPE_DROPDOWN_OPTIONS[0].id,
  };

  const actionColumn = {
    field: "actions",
    type: "actions",
    headerName: "Action",
    getActions: (params: GridRowParams) => {
      const actions = [
        <GridActionsCellItem
          icon={<EditIcon />}
          onClick={() => navigate(`/edit-product/${params.id}?page=${itemListRequestState.pageIndex}`)}
          label="Edit"
        />,
      ];
  
      if (sessionService.hasPermission(Permissions.DeleteProject)) {
        actions.push(GRID_ACTION_DELETE_ICON(params, handleDeleteModalOpen));
      }
  
      return actions;
    },
  };

  const handleClose = () => {
    setDeleteOpenModal(false);
  }
  
  const handleDeleteModalOpen = (params) => {
    setDeleteOpenModal(true);
    setProjectId(params.row.id);
  };

  const deleteSubmit = async () => {
    try{
      api
        .delete<ApiResponse<boolean>>(`/Item/${projectId}`)
        .then( async (response:any) => {
          if(response.isSuccess){
            toast.success(response.message);
            setDeleteOpenModal(false);
            await getItems();
          }
        })
        .catch(() => {});
    }catch(error: any) {
      toast.error(error.message);
    }
  };

  const handleSyncSAPComponent = () => {
    api.post<ApiResponse<boolean>>("/component/sap-syncup", null)
      .then(async (response) => {
        if(response?.isSuccess) {
          toast.success(response?.message);
          await getItems();
        }
        else {
          toast.error(response?.message);
        }
      })
      .catch((error) => {
        console.error("Exception from Sync SAP Component", error);
      });
  };

  const gridToolbar = () => (
    <GridToolbarContainer>
      <div style = {{marginLeft: "12px"}}>
      <Tooltip title="Cannot select more than 1000 records">
        <FormControlLabel 
          sx = {{'& .MuiTypography-root': { fontSize: '13px', fontWeight: 500 }}}          
          control={
            <Checkbox 
              checked={allRecordsSelected}
              disabled={ productsTotalCount > 1000}
              onChange={handleSelectAll}
            />
          } 
          label={`SELECT ALL(${productsTotalCount})`}          
        />
        </Tooltip>
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
        <ExportProductListing
          selectedRows={selectedRows}
          productsTotalCount={productsTotalCount}
          selectAll={allRecordsSelected}
          setLoading={setLoading}
        />
      </div>
      <div style={{
        flexGrow: 1, textAlign: "center", padding: 5,
        display: "flex", justifyContent: "flex-end"
      }}>
        <GridToolbarQuickFilter sx={{ margin: "5px 30px 5px 5px", width: "30%" }} />
        {sessionService.hasPermission(Permissions.CreateProduct)
          && <Button variant="contained"
            component="a"
            href="/create-product"
          >
            <Add />
            Create Product
          </Button>}
        {sessionService.hasPermission(Permissions.ManageBOM) &&
          <Button variant="contained"
            component="a"
            sx={{ margin: "0 4px" }}
            onClick={handleSyncSAPComponent}
          >
            <SyncIcon />
            Sync SAP Components
          </Button>}
        <Button
          variant="contained"
          onClick={async () => await handleExtractAttachment()}
        >
          <FileDownloadRounded />
          Extract Attachments
        </Button>
      </div>
    </GridToolbarContainer>
  );

  useEffect(() => {
    getItems();
  }, [itemListRequestState]);  

  const getItems = async () => {
    try {
      setLoading(true);
      api
        .post<ApiResponse<ProductListResponse>>("/item/list", itemListRequestState)
        .then((response) => {
          setLoading(false);
          if (response.isSuccess) {
            setProductsTotalCount(response?.data?.totalCount);
            const items:ItemBasicDetail[] = (response?.data?.items || []);
            setItems(items);
            // if all rows are selected then update the selectedRows state so that grid reflects with all rows selected
            if(allRecordsSelected) {
              const itemsForSelection = items.filter(i => i.stockcode || i.baseCode).map(i => { 
                return {
                  id: i.id,
                  stockCode: i.stockcode,
                  baseCode: i.baseCode
                } 
              });
             setSelectedRows(selectedRows.concat(itemsForSelection.filter(ii => !selectedRows.some(sr => sr.id === ii.id))));
            }
          } else {
            throw new Error(response.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("Exception from product list", error);
        });
    } catch (error: any) {
      setLoading(false);
      console.error("Exception from product list", error);
    }
  };

  // if sorted by a field which is not direct field of product table then find the correct column from mapping
  const getSortFieldName = (gridFieldName: string): string => {
    return ProductListReferenceColumnsMapping[gridFieldName] || gridFieldName;
  };

  // convert state sortBy value to column name that is bound in grid
  const getReverseSortFieldName = (fieldName: string): string => {
    const gridFieldName = Object.keys(ProductListReferenceColumnsMapping).find(
      (prmKey) => ProductListReferenceColumnsMapping[prmKey] === fieldName
    );
    return gridFieldName || fieldName;
  };

  const onSortChange = (sorting: GridSortModel) => {
    dispatch(updateItemListRequest({
      ...itemListRequestState,
      sortBy: getSortFieldName(sorting[0].field),
      sortDirection:
        sorting[0].sort === "asc" ? SortDirection.ASC : SortDirection.DESC,
    }));
  };

  const onPaginationChange = (pagination: GridPaginationModel) => {
    dispatch(updateItemListRequest({
      ...itemListRequestState,
      pageIndex: pagination.page + 1,
      pageSize: pagination.pageSize,
    }));
  };

  const handleApplyFilterClick = (filterValues: SelectedFilters) => {
    if (filterValues.projectCode) {
      setSelectedRows([]);
      setAllRecordsSelected(false);
      
      dispatch(updateItemListRequest({
        ...itemListRequestState,
        projectCode: filterValues.projectCode,
        pageIndex: 1,
      }));
    } else if (Object.values(filterValues)?.length) {
      setSelectedRows([]);
      setAllRecordsSelected(false);

      dispatch(updateItemListRequest({
        ...itemListRequestState,
        ...filterValues,
        pageIndex: 1,
      }));
    } else {
      //reset the filters
      dispatch(updateItemListRequest(initialItemListRequest));
      setAllRecordsSelected(false);
      setSelectedRows([]);
    }
  };

  const handleCloseModal = () => {
    setOpenExtractAttachment(false);
  };
    
  const downloadFile = (response: any) => {
    if (response.isSuccess) {
        const fileName = response.data;
  
        // Open new window for download
        window.open(`${process.env.REACT_APP_API_URL}/Attachment/download-attachment-zip?fileName=${fileName}`, '_blank');
    } else {
      toast.error(response.message); 
    }
  };

  const handleSelectAll = () => {
    const allRecordsSelectedNewValue = !allRecordsSelected;
    setAllRecordsSelected(allRecordsSelectedNewValue);
    // push current page's records into the selectedRows state so that grid shows them selected.
    // Other pages will be pushed to selectedRows as and when user nagivates through pages
    if(allRecordsSelectedNewValue === true) {
      const itemIDs = items.filter(i => i.stockcode || i.baseCode).map(i => { 
        return {
          id: i.id,
          stockCode: i.stockcode,
          baseCode: i.baseCode
        } 
      });
      setSelectedRows(selectedRows.concat(itemIDs.filter(ii => !selectedRows.some(sr => sr.id === ii.id))));
    }
    else {
      setSelectedRows([]);
    }
  };

  const onRowSelectionModelChange = (rowIDs: GridRowId[]) => {
    // update the selectedRows state only if allRecordsSelected is false.
    if(!allRecordsSelected) {
      // NOTE: rowIDs contains IDs of all rows that were previously selected + new one that trigged this event
      //get stockCode and baseCode for the selected rows and store in state
      const selectedItems = items.filter(i => rowIDs.includes(i.id));    
      const newSelection = selectedItems.map(i => {
        return {
          id: i.id,
          stockCode: i.stockcode,
          baseCode: i.baseCode
        }
        //then filter the duplicate records for new selection.
      }).filter(newlySelectedItem => !selectedRows.some(sr => sr.id === newlySelectedItem.id));
      
      // if product was unchecked, remove it from previously selectedRows.
      const existingSelection = selectedRows.filter(sr => rowIDs.includes(sr.id));
      
      //set previous selection and add new selection.
      setSelectedRows([...existingSelection, ...newSelection]);
    }
  };

  const handleExtractAttachment = () =>{
    //i think this is okay for validation 
    if (selectedRows.length <= 0 && !allRecordsSelected) {
      toast.error("There is no Stock Code or Base Code, Please add record that have Stock Code or Base Code");
    } 
    else if(selectedRows.length>1000) {
      toast.error("Attachments can be downloaded for maximum 1000 records in one request");
    } 
    else {
      setOpenExtractAttachment(true);
    }
  };

  const handleExtractSubmit = async (itemIds, attachmentTypes) => {
    const sessionInfo: any = JSON.parse(
      window?.localStorage.getItem("session") || "{}"
    );
    try{
        setDisableExtractAttachmentSubmit(true);
        setLoading(true);
        let response = null;
        !allRecordsSelected ? response = await fetch(`${process.env.REACT_APP_API_URL}/Attachment/extract-attachments-list`, {
          method: "POST",
          headers: { 
            "Content-Type": "application/json",
            "Authorization": "Bearer " + sessionInfo.token,
          },
          body: JSON.stringify({
            itemId: itemIds,
            attachmentTypes: attachmentTypes
          })
        }) : response = await fetch(`${process.env.REACT_APP_API_URL}/Attachment/extract-attachments-with-filter`, {
          method: "POST",
          headers: { 
            "Content-Type": "application/json",
            "Authorization": "Bearer " + sessionInfo.token,
          },
          body: JSON.stringify({
            filterCriteria: itemListRequestState,
            attachmentTypes: attachmentTypes
          })
        })

        if(response.ok){
          const responseBodyContent = await response.json();
        
          if(responseBodyContent.message){
            toast.error(responseBodyContent.message); 
            setLoading(false);
            setDisableExtractAttachmentSubmit(false);
          } else {
            downloadFile(responseBodyContent);
            setSelectedRows([]);
            setOpenExtractAttachment(false);
            setAllRecordsSelected(false);
            setDisableExtractAttachmentSubmit(false);
            setLoading(false);
            toast.success("Attachments Exported Successfully")
          }
        }
    }
    catch (err: any) {
      setLoading(false);
      toast.error(err.message);
    }
  };

  const handleExpandClick = () => {
    setFiltersExpanded(!filtersExpanded);
  };

  return (
    <>
      <DeleteValidationModal 
        addModalOpen={deleteModalOpen} 
        handleCloseModal={handleClose} 
        deleteSubmit={deleteSubmit} 
        fieldIdentifier={`project #${projectId}`} 
      />
      {openExtractAttachment && <ExtractAttachmentsModal
        isOpen={openExtractAttachment}
        onClose={handleCloseModal}
        selectedItems={selectedRows}
        onSubmit={handleExtractSubmit}
        allSelected={allRecordsSelected}
        disableExtractAttachmentSubmit={disableExtractAttachmentSubmit}
        isLoading={isLoading}
      />
      }
      <Filters
        onApplyFilter={handleApplyFilterClick}
        filtersExpanded={filtersExpanded}
        handleExpandClick={handleExpandClick}
        viewMode={PermissionsFriendlyNames[Permissions.ViewProducts]}
      />
      <Loader isLoading={isLoading} />
      <Box
        sx={{
          height: "70%",
          width: "100%",
          display: "block",
        }}
      >
        <DataGrid
          rows={items}
          columns={[actionColumn, ...ProductListGridColumns]}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10
              },
            },
          }}
          rowCount={productsTotalCount}
          pageSizeOptions={[10, 25, 50, 100]}
          disableRowSelectionOnClick={true}
          disableColumnFilter={true}
          sortingMode="server"
          paginationMode="server"
          sortModel={[
            {
              field: getReverseSortFieldName(itemListRequestState.sortBy),
              sort:
              itemListRequestState.sortDirection == SortDirection.ASC
                  ? "asc"
                  : "desc",
            },
          ]}
          onSortModelChange={onSortChange}
          onPaginationModelChange={onPaginationChange}
          paginationModel={{
            page: itemListRequestState.pageIndex - 1, 
            pageSize: itemListRequestState.pageSize,
          }}
          slots={{
            toolbar: gridToolbar,
            noRowsOverlay: () => NO_ROWS_GRID_MESSAGE,
            noResultsOverlay: () => NO_LOCAL_SEARCH_RESULTS_GRID_MESSAGE,
          }}
          checkboxSelection={true}
          isRowSelectable={(params: GridRowParams) => (!allRecordsSelected && (params.row.stockcode || params.row.baseCode)) }
          onRowSelectionModelChange={onRowSelectionModelChange}
          rowSelectionModel={selectedRows.map(sr => sr.id)}
          //to keep selection from previous page
          keepNonExistentRowsSelected
          hideFooterSelectedRowCount = {allRecordsSelected}
          getRowHeight = {()=> 'auto'}
          // columnVisibilityModel={{
          //   actions: (sessionService.hasPermission(Permissions.ProductUpdateFullAccess)||
          //   (sessionService.hasPermission(Permissions.ManageAttachments)))
          // }}
        />
      </Box>
    </>
  );
}
